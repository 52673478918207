import React from 'react'
import css from "../vitual/vitual.module.css"
import shadeimg from "../../assets/shade.png"
import before from "../../assets/before.png"
import after from "../../assets/after.png"
import ReactCompareImage from 'react-compare-image'
export default function Virtualls() {
  return (
    <div className={css.container}>

        <div className={css.leftarea}>
            <span>Virtual Try -On</span>
            <span>Never Buy a wrong shade Again!</span>
            <span>Try NOW!</span>
            <img src={shadeimg} alt="shadeimg"></img>
        </div>

        <div className={css.rightside}>
            <div className={css.beforeafter}>
           <ReactCompareImage leftImage={before}rightImage={after}/>
           </div>
        </div>
    </div>
  )
}
