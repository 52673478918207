import  Virtualls  from "./component/vitual/vitual";
import "./App.css";
import Header from "./component/header/header";
import Middlepart from "./component/middle/middlepart";
import Slidesr from "./component/slider/sliderpart";
import Productslide from "./component/product/productslide";
import Testimony from "./component/testimony/testimony";
import Footers from "./component/footer/footer";

function App() {
  return (
    <div className="App">
<Header/>
<Middlepart/>
<Slidesr/>
<Virtualls/>
<Productslide/>
<Testimony/>
<Footers/>
    </div>
  );
}

export default App;
