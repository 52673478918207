import React from 'react'
import css from "./footer.module.css"
import logo from "../../assets/logo.png"
import { InboxIcon, PhoneIcon, LocationMarkerIcon, LoginIcon, UsersIcon, linkIcon, LinkIcon } from "@heroicons/react/outline"
export default function Footers() {
    return (
        <>
        <div className={css.containerfooter} >
         <hr/>
<div className={css.container} >

            
            <div className={css.footer}  >

                    <div className={css.footerleft}> 
                        <div className={css.logo}>
                            <img src={logo} alt=""></img>
                            <span>Pearl Beauty</span>
                        </div>
                    </div>

                    <div className={css.footerright} >

                    <div className={css.blocks}>
                        <div className={css.detail}>
                            <span> Contact us</span>
                            <span className={css.line}>
                                <LocationMarkerIcon className={css.icon} />
                                <span>3,Old post office street,kamuthi</span>
                            </span>
                            <span className={css.line}>
                                <PhoneIcon className={css.icon} />
                                <span>97564689</span>
                            </span>
                            <span className={css.line}>
                                <InboxIcon className={css.icon} />
                                <a href="">support@pearlbeauty.com</a>
                            </span>
                        </div>
                    </div>
                   
                     <div className={css.blocks}>
                        <div className={css.detail}>
                            <span> Account</span>
                            <span className={css.line}>

                                <LoginIcon className={css.icon} />
                                <a href="/about">
                                    <p>Signin</p>
                                </a>

                            </span>
                        </div>
                    </div>
                  
                    <div className={css.blocks}>
                        <div className={css.detail}>
                            <span> Company</span>
                            <span className={css.line}>

                                <UsersIcon className={css.icon} />
                                <a href="/about">
                                    <p>About us</p>
                                </a>

                            </span>
                        </div>
                    </div>
                  
                    

                    <div className={css.blocks}>
                        <div className={css.detail}>
                            <span> Resources</span>
                            <span className={css.line}>

                                <LinkIcon className={css.icon} />
                                <span>safety privacy &Terms</span>
                            </span>
                        </div>
                    </div>

                    </div>
                    
                    
            </div>

            <div className={css.copyRight}>
                <span>Copyright @2021 by PearlBeauty</span>
                <span>All rights reserved</span>
            </div>

 </div>
 </div>
        </>
    )
}
