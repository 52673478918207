import React from 'react'
import css from "./testimony.module.css"
import testimg from "../../assets/testimonialHero.png"
import {Swiper,  SwiperSlide } from 'swiper/react'
import {TestimonialsData} from "../../data/testimonials"
export default function Testimony() {
  return (
    <div className={css.container}>

      <div className={css.wrapper}>
          <div className={css.left}>
            <span> Top Rated</span>
            <span> 500k plus customer satisfied my product.Seedily say has suitable dispoyal and boy.  </span>
          </div>
          <img src={testimg} alt=""></img>
          <div className={css.right}>
            <span> 100k </span>
            <span>Happy Customers With us</span>
          </div>
      </div>
 <div className={css.review}>Reviews</div>
      <div className={css.testimonial}>
      <Swiper slidesPerGroup={1}
      slidesPerView={3}
      spaceBetween={20}
      className={css.testiswiper}>{
        TestimonialsData.map((item,i)=>
        <SwiperSlide>
          <div className={css.testibody}>
            <img src={item.image} alt="testimonial img"></img>
            <span>{item.comment}</span>
            <hr/>
            <span>{item.name}</span>
          </div>
        </SwiperSlide>)
      }
      </Swiper>
      </div>

    </div>
  )
}
