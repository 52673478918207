import React from 'react'
import css from "./middlepart.module.css"
import heroimg from "../../assets/hero.png"
import { RiShoppingBag2Fill } from "react-icons/ri"
import { BsArrowRight } from "react-icons/bs"

export default function Middlepart() {
    return (
        <div className={css.container}>

            {/* left side */}
            <div className={css.containerleft}>
                <span className={css.text1} style={{paddingLeft:"60px"}}>skin product cream</span>
                <div className={css.text2}>
                    <span style={{paddingLeft:"30px"}}>Trendy Collection</span>
                    <span style={{paddingLeft:"30px"}}>Creamy Product has suitable for both gender andthn improve your skin color</span>
                </div>
            </div>

            {/* middle side */}
            <div className={css.warpper}>
                <div className={css.blueCircle}> </div>
                <img src={heroimg} alt="" width={600}></img>
                <div className={css.cart2}>
                    <RiShoppingBag2Fill></RiShoppingBag2Fill>
                    <div className={css.signup}>
                        <span>BestSignup Offers</span>

                    <div>
                    <BsArrowRight />
                    </div>
                  </div>
                </div>

            </div>

            {/* right side */}
            <div className={css.containerright}>
                <div className={css.traffic} style={{paddingTop:"30px"}}>
                    <span>1.5m</span>
                    <span style={{paddingRight:"30px"}}>Monthly Traffic</span>
                </div>
                <div className={css.customer} style={{paddingTop:"30px"}}>
                    <span>100k</span>
                    <span style={{paddingRight:"30px"}}>Happy customer</span>
                </div>
            </div>


        </div>
    )
}
