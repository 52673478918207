import { Navigation, Pagination, } from 'swiper';
import css from "../slider/slider.module.css"
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import { SliderProducts } from "../../data/products"
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';

export default function Slidesr() {
    return (
        <div className={css.container}>

            <Swiper className={css.swiper}
                modules={[Navigation, Pagination]}
                navigation={true}
                pagination={true}
                loopFillGroupWithBlank={true}
                spaceBetween={50}
                slidesPerView={3}
                slidesPerGroup={1}
                loop={true}>

                {SliderProducts.map((slide, i) =>
                    <SwiperSlide className={css.swiperslide}>
                        <div className={css.slideleft}>
                            <div className={css.slideleft1}>
                                <div className={css.name}>
                                    <span>{slide.name}</span>  
                                    <span>{slide.detail}</span>
                                </div>
                                <span className={css.price}>{slide.price}$</span>

                                <div className={css.shop}>Shop now</div>
                                </div>
                                <img src={slide.img} style={{ height: "144px", transform: "rotate(-20deg)",
 width: "63px"}} alt={slide.name}></img>
                         

                        </div>
                    </SwiperSlide>
                )}

            </Swiper>
        </div>
    );
};

