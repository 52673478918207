import React, { useState,useEffect } from 'react'
import css from "../product/product.module.css"
import plane from "../../assets/plane.png"
import { ProductsData } from '../../data/products'
export default function Productslide() {
    const [menuproduct, setMenuproduct] = useState(ProductsData);
     useEffect(() => {

    },[setMenuproduct]);
    const filter=(type)=>{
        setMenuproduct(ProductsData.map((item,i)=>item.type=== type))
        console.log(menuproduct)
    }
    return (
        <div className={css.container}>

            <img src={plane} alt="plane.png"></img>
            <h1>Our Featured Products</h1>

            <div className={css.produucts}>

                <ul className={css.menu}>
                    <li onClick={()=>setMenuproduct(ProductsData)}>All</li>
                    <li onClick={()=>filter("skin care")}>Skin Care</li>
                    <li onClick={()=>filter("conditioner")}>Conditioners</li>
                    <li onClick={()=>filter("foundation")}>Foundations</li>
                </ul>

                <div className={css.list}>
                    {menuproduct.map((item, i) =>
                    
                        <div className={css.productlist}>
                            <div className={css.leftside}>
                                <div className={css.name}>
                                    <span>{item.name}</span>
                                    <span>{item.detail}</span>
                                </div>
                                <span className={css.price}>{item.price}$</span>
                                <div className={css.shop}> Shop Now</div>
                            </div>
                            <img src={item.img}style={{ height: "124px", transform: "rotate(-20deg)",width: "50px"}} alt="slide.name"></img>
                         
                        </div>
                    )}
                </div>

            </div>


        </div>

    )
}
