import React, { useState } from 'react'
import css from "./header.module.css"
import pearls from "../../assets/logo.png"
import {CgShoppingBag } from 'react-icons/cg'
import{GoThreeBars} from "react-icons/go"
export default function Header() {
    const [menushow,setMenushow]=useState(true);
    return (
        <div className={css.container}>
            <div className={css.logo}>
                <img src={pearls}></img>
                <span>Pearls Beauty</span>
            </div>


            <div className={css.right}>
                 {/* <div className={css.bars}>
                    <GoThreeBars/>
                </div> */}
                <div className={css.menu}>
                    <ul className={css.menu}>
                        <li>Collection</li>
                        <li>Brand</li>
                        <li>New</li>
                        <li>Sales</li>
                        <li>Order</li>
                    </ul>
                </div>
                <input type="text" className={css.search} />
               <CgShoppingBag className={css.cart} />
            </div>

          
        </div>
    )
}
